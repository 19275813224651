/* eslint-disable @typescript-eslint/no-explicit-any */
import Rpc from "@/utils/Rpc";
import { message } from "ant-design-vue";
import Vue, { PropType } from "vue";
export default Vue.extend({
  props: {
    value: String as PropType<string>,
    setValue: Function as PropType<any>,
    clickSearch: Function as PropType<any>,
  },
  data() {
    return {
      Value: "" as any,
      loading: false,
    };
  },
  computed: {
    maxHeight(): number {
      return this.$store.state.maxHeight;
    },
    placeholder(): string {
      return this.$t("lang.placeholder") as string;
    },
  },
  methods: {
    search(): any {
      this.loading = true;
      const reg = /^[0-9]*[1-9][0-9]*$/;
      this.Value = this.Value.trim();
      if (this.Value.trim() == "") {
        this.loading = false;
        return message.warning(this.$t("lang.placeholder") as string);
      }
      if (reg.test(this.Value.trim()) || this.Value == 0) {
        if (Number(this.Value.trim()) <= Number(this.maxHeight)) {
          //判断两个高度差---int类型比较
          Rpc.getBlockHash(Number(this.Value)).then((data) => {
            if (data.error == null) {
              this.$router.push({
                path: "/blockDetail",
                query: {
                  height: this.Value.trim(),
                },
              });
              this.Value = "";
              this.loading = false;
            } else {
              message.warning(this.$t("lang.components.errMsg") as string);
              this.Value = "";
              this.loading = false;
            }
          });
        } else {
          message.warning(this.$t("lang.components.errHeight") as string);
          this.Value = "";
          this.loading = false;
        }
      } else {
        const e = this.Value.length;
        if (e === 66 || e === 64) {
          this.Value = e === 64 ? "0x" + this.Value : this.Value;
          //交易的哈希值---地址64位字节加上16进制标示0x(共66位)
          Rpc.queryTransaction(this.Value).then((data) => {
            if (data.error == null) {
              this.$router.push({
                path: "/tradeHash",
                query: {
                  hash: this.Value,
                },
              });
              this.Value = "";
              this.loading = false;
            } else {
              //区块哈希
              Rpc.getBlockOverview(this.Value).then((data) => {
                if (data.error == null) {
                  this.$router.push({
                    path: "/blockDetail",
                    query: {
                      height: data.result.head.height,
                    },
                  });
                  this.Value = "";
                  this.loading = false;
                } else {
                  message.warning(this.$t("lang.components.errMsg") as string);
                  this.Value = "";
                  this.loading = false;
                }
              });
            }
          });
        } else {
          //地址
          Rpc.getAddrOverview(this.Value).then((data) => {
            if (data.error == null) {
              this.$router.push({
                path: "/address",
                query: {
                  address: this.Value,
                  way: "0",
                },
              });
              this.Value = "";
              this.loading = false;
            } else {
              message.warning(this.$t("lang.components.errMsg") as string);
              this.Value = "";
              this.loading = false;
            }
          });
        }
      }
    },
    searchResult(v: any) {
      this.Value = v || "";
      this.search();
    },
  },
  watch: {
    Value(newV: any) {
      if (newV === "") {
        this.setValue && this.setValue("");
      }
    },
  },
});
